.table-container {
    margin-top: 1rem;
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &.no-margin {
        margin: 0;
    }

    &.no-pagination {
        max-height: 75vh;
        margin-bottom: 1rem;

        table {
            min-height: auto;
        }
    }

    &.no-border {
        border: none;
    }

    table {
        &.auto-height {
            min-height: auto;
        }

        &.mat-table {
            width: 100%;
            margin: 0;
        }

        .mat-row:nth-child(odd) {
            background-color: rgba(240, 240, 240, 0.33);
        }

        .mat-row:hover {
            background-color: rgba(240, 240, 240, 1);
        }

        td {
            mat-form-field {
                &.zero-bottom-padding {
                    .mat-form-field-wrapper {
                        padding-top: 6px;
                        padding-bottom: 4px;
                    }
                }
            }

            &.status-column {
                padding-left: 8px !important;
                padding-right: 8px;
            }

            &.actions {
                text-align: right;

                button {
                    margin-right: 0.5rem;

                    &:last-child {
                        margin-right: 0rem;
                    }
                }
            }

            img {
                max-width: 128px;
            }
        }
    }
}

.mat-paginator {
    border-top: 1px solid #e0e0e0;
    margin-bottom: 1rem;

    &.mat-paginator-sticky {
        position: sticky;
        bottom: 0;
        z-index: 10;
        margin-bottom: 0;
    }
}
