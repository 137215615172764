mat-slide-toggle {
    padding: 1rem 0rem;

    &.open {
        .mat-slide-toggle-thumb {
            background-color: $bleachr-green !important;
        }
        .mat-slide-toggle-bar {
            background-color: lighten($bleachr-green, 30%) !important;
        }
    }

    &.closed {
        .mat-slide-toggle-thumb {
            background-color: $bleachr-red !important;
        }
        .mat-slide-toggle-bar {
            background-color: lighten($bleachr-red, 30%) !important;
        }
    }

    &.no-extra-style {
        border: 0;
        padding: 0;
        border-radius: 0;
    }
}

// Not sure this is used...
// Peter / Vela have a look and confirm
// .open.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
//     background-color: rgba(90, 190, 130, 0.54) !important;
// }
// .open.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//     background-color: rgb(90, 190, 130) !important;
// }

// .closed div.mat-slide-toggle-bar {
//     background-color: rgba(233, 106, 106, 0.54) !important;
// }
// .closed div.mat-slide-toggle-thumb {
//     background-color: rgb(233, 106, 106) !important;
// }
