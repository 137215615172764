.color-picker-input-container {
    flex-basis: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0rem 0rem 1.5rem;
    align-items: baseline;

    label {
        min-width: 45%;
    }

    button {
        flex: 1;
    }

    span {
        min-width: 4rem;
    }

    .color-picker-input {
        border: 1px solid #afafaf;
        padding: 0.5rem 0;
        width: 100%;
        flex: 1;
        margin-left: 8px;
        border-radius: 6px;
    }
}
