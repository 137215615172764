@import './variables.scss';

.circle-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin: 0rem 0.25rem;

    &.enabled,
    &.complete,
    &.success,
    &.live,
    &.open {
        background-color: $bleachr-green;
    }

    &.disabled,
    &.closed,
    &.archived {
        background-color: #e5e4e2 !important;
    }

    &.hidden {
        background-color: $bleachr-red;
    }
}

.icon-indicator {
    color: #78909c;

    &.enabled,
    &.complete,
    &.success,
    &.live,
    &.open {
        color: #4caf50;
    }

    &.disabled,
    &.closed {
        color: #78909c;
    }
}

.circle-icon-indicator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.in-valid {
        background-color: rgba(120, 144, 156, 0.8);
    }

    &.valid {
        background-color: $bleachr-green;
    }

    mat-icon {
        color: $bleachr-white;
        font-size: 21px;
        width: 21px;
        height: 21px;
    }
}
