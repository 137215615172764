mat-list-item,
mat-list-option {
    border-bottom: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #fafafa;
    margin-bottom: 0.25rem;

    &.active-selection {
        background-color: #b1bfca;
        border-color: transparent;
    }
}

// custom version

.cards-section-list {
    .card-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $bleachr-border-color;
        border-radius: 4px;
        margin-bottom: 1rem;
        padding: 0.25rem 0.5rem;

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }
}

.app-feed-item-option .mdc-list-item__primary-text{
    flex: 1;
}