.header {
    padding: 1rem 0.75rem;
    text-transform: capitalize;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 1.25rem;

    &.live {
        background-color: #4caf50;
    }

    &.pending {
        background-color: #ffce44;
    }

    &.expired {
        background-color: #e53935;
    }

    &.scheduled {
        background-color: #f57c00;
    }
}

.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-weight: 600;
}

.body {
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: 300;

    .header-row,
    .row {
        .flex-05 {
            flex: 0.5;
        }

        .flex-1 {
            flex: 1;
            padding: 0rem 0.25rem;
        }

        .flex-2 {
            flex: 2;
        }

        .time-remaining {
            display: flex;
            flex-direction: column;
        }

        .locale {
            padding: 3px 6px;
            margin: 4px 0px;
            background-color: #1976d2;
            border-radius: 8px;
            color: white;
            text-transform: uppercase;
            text-align: center;
            display: inline-block;
        }

        .question-text {
            display: inline-block;
            padding-left: 0.25rem;
        }
    }

    .rows {
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5rem;
            border-bottom: 1px solid #e0e0e0;
            align-items: center;

            &:nth-child(odd) {
                background-color: rgba(240, 240, 240, 0.33);
            }

            &:hover {
                background-color: #eeeeee;
            }

            &:first-child {
                border-top: 1px solid #e0e0e0;
            }

            &:last-child {
                border-bottom: 0px;
            }
        }
    }
}
