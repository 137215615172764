.cloudinary-library-container {
    display: none;

    &.active {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 9999;
        padding: 3rem;
        left: 0;
        top: 0;
    }

    .close-button {
        background-color: #fff;
        width: 42px;
        height: 42px;
        position: fixed;
        top: 0px;
        left: 0;
        cursor: pointer;
    }

    .cloudinary-container {
        width: calc(100% - 6rem);
        height: calc(100vh - 6rem);
    }
}
