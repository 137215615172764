.chip-container {
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0rem;

    .chip {
        display: inline-block;
        min-width: 2rem;
        background-color: #1976d2;
        border-radius: 16px;
        text-align: center;
        padding: 1px 3px 1px 3px;
        text-transform: uppercase;
        font-size: 0.9rem;
        color: #fff;
        line-height: 1.2rem;
        overflow: hidden;
    }

    .chip-text {
        display: inline-block;
        border: 1px solid #1976d2;
        padding: 0px 12px 0px 15px;
        margin-left: -10px;
        font-size: 1rem;
        z-index: 0;
        border-left: 0;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        line-height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 175px;
    }
}
