@import './variables.scss';

.checkbox-form-field {
    margin-bottom: 1.5rem;
    border: 1px solid $bleachr-border-color;
    padding: 1rem;
    border-radius: 4px;
    display: flex;

    mat-checkbox {
        margin-bottom: 0;
    }
}
