// Bleachr Colors
$bleachr-white: #fefefa;
$bleachr-green: #00a550;
$bleachr-red: #f44336;
$bleachr-blue: #2196f3;
$bleachr-gray: #90a4ae;
$bleachr-yellow: #ffde03;
$bleachr-black: #000000;
$bleachr-purple: #484dd0;

:root {
    --section-header: #536878;
    --bleachr-white: #fefefa;
    --scrollbar-color: #bebfc5;
}

//
$bleachr-border-color: #e0e0e0;
$bleachr-hover-color: #f5f5f5;

// Darkmode Theming
$darkmode--background-color: #33333d;
$darkmode--panel-background-color: #373740;
