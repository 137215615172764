.editor-toolbar {
    &.fullscreen {
        margin-top: 4rem;
    }
}

.CodeMirror {
    &.CodeMirror-fullscreen {
        margin-top: 4rem;
    }
}

.editor-preview-side {
    &.editor-preview-active-side {
        margin-top: 4rem;
    }
}

.mat-drawer.mat-drawer-side {
    z-index: 1 !important;
}

#sidenav {
    width: 100%;
}

#sidenav.menu-open {
    width: 100%;
    // -webkit-transition: width 0.5s ease-in-out;
    // -moz-transition: width 0.5s ease-in-out;
    // -o-transition: width 0.5s ease-in-out;
    // transition: width 0.5s ease-in-out;
}

#sidenav.menu-close {
    width: 30%;
    -webkit-transition: width 0.1s ease-in-out;
    -moz-transition: width 0.1s ease-in-out;
    -o-transition: width 0.1s ease-in-out;
    transition: width 0.1s ease-in-out;
}

.CodeMirror-line {
    padding: 0px 24px 0px 4px !important;
}

.CodeMirror-sided-phone {
    min-width: calc(100% - 414px) !important;
}

.editor-preview-side-phone {
    max-width: 414px;
}
