.filter-container {
    border: 1px solid $bleachr-border-color;
    border-radius: 4px;
    margin: 1rem 0rem;
    padding: 1rem;
    background: $bleachr-white;

    .row {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        mat-form-field {
            flex: 1;
            padding: 0rem 0.5rem;

            &.dropdown-options {
                flex: 0.2 !important;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &.zero-bottom-padding {
                .mat-form-field-wrapper {
                    padding-bottom: 0;
                }
            }
        }

        .search-container {
            flex: 1;

            mat-form-field {
                width: 100%;

                &.zero-bottom-padding {
                    .mat-form-field-wrapper {
                        padding-bottom: 0;
                    }
                }
            }
        }

        mat-slide-toggle {
            margin: 0rem 2rem;
        }
    }

    .footer-row {
        text-align: right;

        button {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// hack for enet view
.enet-tournaments {
    .filter-container {
        .mat-form-field-wrapper {
            margin: 0;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .filter-container {
        .row {
            flex-direction: column;
            justify-content: center;

            mat-form-field {
                padding: unset;
            }
        }
    }
}
