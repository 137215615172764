.drawer {
    width: 650px;
    background-color: #ffffff;
    height: calc(100% - 4rem);
    position: fixed;
    top: 4rem;
    right: -650px;
    z-index: 101;
    transition: all 0.5s ease-out;
    border-left: 1px solid #e2e2e2;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 4px 0px 8px 0px rgb(0 0 0 / 30%);

    &.l {
        width: 700px;
        right: -700px;

        .footer-actions {
            width: calc(700px - 3rem);
        }
    }

    &.xl {
        width: 800px;
        right: -800px;

        .footer-actions {
            width: calc(800px - 3rem);
        }
    }
    &.xxl {
        width: 1000px;
        right: -1000px;

        .footer-actions {
            width: calc(1000px - 3rem);
        }
    }

    &.active {
        right: 0;
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-outline {
            border-radius: 5px;
            background-color: #fafafa;
        }
    }

    mat-tab-body {
        .input-container {
            padding: 1.5rem 0rem 0rem;
        }
    }

    mat-form-field {
        .mat-form-field-suffix {
            display: flex;
        }
    }

    .input-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1.5rem 0rem;
        margin-bottom: 4.25rem !important;

        .answers-section {
            margin-bottom: 0rem !important;
        }

        .locale-input-container {
            display: flex;
            align-items: baseline;

            .input-label {
                border: 1px solid;
                border-right: 0;
                padding: 24px 1rem 15px 1rem;
                margin-right: -4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-color: #dcdcdc;
                text-transform: uppercase;
                font-weight: 600;
            }

            mat-form-field {
                flex: 1;
            }
        }

        .collections-container {
            display: flex;
            flex-wrap: wrap;

            mat-checkbox {
                margin: 0.5rem 1rem;
            }
        }

        .row {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            background-size: cover;
            width: 100%;

            app-cloudinary-input {
                flex: 1;
            }

            mat-form-field {
                flex: 1;
            }

            .trash {
                margin: 0.25rem 0;
                padding-bottom: 1.34375rem;
                flex: 0.1;
            }
        }

        .ngif-fix {
            display: flex;
            flex-direction: column;
        }

        .section-header {
            padding: 1rem 0.5rem;
            color: #212121;
            border-radius: 4px;
            margin-bottom: 1.5rem;
            background-color: #90caf9;
            text-transform: uppercase;
            font-weight: 600;
        }

        .nested-inputs {
            display: flex;
            flex-direction: column;
        }

        .nested-row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .nested-inputs-container {
            display: flex;
            flex-direction: column;
            border: 1px solid #afafaf;
            padding: 1.5rem 0.5rem 0;
            border-radius: 4px;
            margin: 1.5rem 0rem 0rem;
            background-color: #eeeeee;

            .title {
                text-transform: uppercase;
                font-size: 1.2rem;
                margin: 0rem 0rem 0.75rem 0.25rem;
                font-weight: 500;
            }
        }

        .answer {
            display: flex;
            flex-direction: column;
            border: 1px solid #afafaf;
            padding: 1.5rem 0.5rem 0;
            border-radius: 4px;
            margin: 0.75rem 0rem 0.75rem;
            background-color: #f1f1f1;

            mat-checkbox {
                padding: 0rem 0.25rem 1rem;
            }

            .answer-input {
                mat-form-field {
                    width: 100%;
                }
            }
        }

        .spacer {
            margin-bottom: 1.5rem;
        }

        app-date-timepicker {
            margin: 0rem 0rem 1.5rem;
        }

        .slider-input {
            display: flex;
            flex-direction: column;
            width: 100%;

            .label {
                margin-bottom: 1.2rem;
            }
        }

        .mat-slide-toggle {
            &.form-toggle-container {
                width: calc(100% - 2rem);
                margin-bottom: 1.5rem;
            }
        }

        .form-button-container {
            margin-bottom: 1.5rem;

            button {
                width: 100%;
            }
        }
    }

    .footer-actions,
    app-drawer-actions {
        position: fixed;
        bottom: 0;
        border-top: 1px solid #e0e0e0;
        width: calc(650px - 3rem);
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: #fafafa;
        z-index: 2;
    }
}

.insight-drawer {
    width: 900px;
    right: -900px;
}

@media only screen and (max-width: 768px) {
    .drawer {
        width: 100%;
        .input-container {
            margin-top: 80px;
        }
        .footer-actions {
            width: 90%;
            position: absolute;
            top: 0;
            bottom: unset;
        }
    }
}
