.section {
    background-color: $bleachr-white;
    border: 1px solid $bleachr-border-color;
    border-radius: 4px;
    margin: 1rem 0rem;

    h3 {
        padding-left: 1.25rem;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    .section-header {
        padding: 1rem 0.5rem;
        color: #212121;
        border-radius: 4px;
        margin-bottom: 1.5rem;
        background-color: #90caf9;
        text-transform: uppercase;
        font-weight: 600;
    }
}
