/* You can add global styles to this file, and also import other style files */

@import './sass/variables.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/flag-icon-css/flag-icon.scss';
@import './sass/table.scss';
@import './sass/pill.scss';
@import './sass/status-indicator.scss';
@import './sass/drawer.scss';
@import './sass/toggle.scss';
@import './sass/codemirror.scss';
@import './sass/mobile.scss';
@import './sass/filter-container.scss';
@import './sass/section.scss';
@import './sass/event-manager.scss';
@import './sass/chip.scss';
@import './sass/cloudinary.scss';
@import './sass/drag-drop.scss';
@import './sass/list-item.scss';
@import './sass/tennis-one.module.scss';
@import './sass/color-picker.scss';
@import './sass/checkbox.scss';
@import './sass/input.scss';

html,
body {
    height: 100%;
    background-color: #f1f1f1;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;

    &.locked {
        overflow: hidden;

        .navigation-toolbar {
            pointer-events: none;
        }

        mat-tab-header {
            pointer-events: none;
        }
    }

    #main {
        width: calc(100% - 13rem);
        margin: 4rem 1rem 1rem 12rem;

        .mat-tab-header-pagination {
            z-index: 1 !important; /* Hack to prevent nav from overlaying edit drawer */
        }
    }
}

// .mat-horizontal-stepper-header {
//     pointer-events: none !important;
// }

#app-version mat-form-field.mat-form-field .mat-form-field-wrapper {
    padding-bottom: 1px;
}

.cdk-overlay-pane {
    max-width: 92vw !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loading-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(240, 240, 240, 0.2);
    z-index: 2;
}

.image-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #666666;

    img {
        max-width: 8rem;
    }

    button {
        margin-right: 1rem;
    }
}

.gaming-correct {
    color: $bleachr-green;
}

.gaming-incorrect {
    color: $bleachr-red;
}

.account-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0.5rem 0.5rem;
    margin: 2rem 0rem 0rem;
    background: $bleachr-white;
    min-height: 36px;
    // position: sticky;
    // top: 4rem;
    // z-index: 2;

    .flex-1 {
        flex: 1;
    }

    .title {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 500;

        .title-text,
        .indicator-container {
            padding: 0rem 1rem 0rem 0rem;
        }
    }

    button {
        &:first-child {
            margin-right: 1rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .event-meta {
        flex: 1;
        font-size: 1.1rem;

        .starts-at {
            &:before {
                content: 'Starts at';
            }
        }

        .starts-at-time {
            &:before {
                content: 'Opens at';
            }
        }

        .event-location {
            &:before {
                content: 'Location';
            }
        }

        .starts-at,
        .starts-at-time,
        .event-location {
            &:before {
                display: block;
                color: #bdbdbd;
                font-weight: 200;
                padding: 0rem 0rem 0.1rem;
                font-size: 0.9rem;
            }
        }
    }
}

// hack for now

#controls {
    mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }

    .set {
        mat-form-field {
            &:first-of-type {
                margin-right: 1rem;
            }
            .mat-form-field-infix {
                width: 100%;
            }
        }
    }
}

#account-container,
#manager-ui {
    mat-form-field {
        .mat-form-field-suffix {
            display: flex;
        }
    }
}

.mat-mdc-menu-panel {
    max-width: fit-content !important;
}

@media only screen and (max-width: 768px) {
    body {
        #main {
            width: 100%;
            margin: 3rem 0 1rem 0;
            z-index: 102;
        }
    }
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        padding: 3px !important;
    }
}

.court-opition {
    .mat-option-text {
        display: flex;
        justify-content: space-between;
    }
}

app-s3-uploader {
    app-file-uploads {
        .table-container {
            height: 75%;
        }
    }
}

.add-padding-dialog {
    app-s3-uploader {
        display: block;
        padding: 1rem;
        height: calc(100% - 2rem);
    }
}
