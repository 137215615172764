.pill {
    padding: 4px 8px;
    border-radius: 2px;
    font-size: 0.75rem;
    font-weight: 300;
    text-transform: capitalize;
    min-width: 64px;
    display: inline-block;
    text-align: center;
    color: $bleachr-white;
    background-color: #00897b; // default

    &.mini {
        min-width: auto;
    }

    &.upcoming {
        background-color: $bleachr-blue;
    }

    &.live,
    &.open {
        background-color: $bleachr-green;
    }

    &.complete,
    &.past {
        background-color: $bleachr-gray;
    }

    &.cancel,
    &.canceled,
    &.closed {
        background-color: $bleachr-red;
    }

    &.awaiting {
        background-color: $bleachr-yellow;
        color: $bleachr-black;
    }
}

.mat-option {
    .pill {
        padding: 0;
        line-height: 1.5rem;
    }

    .team-pill {
        font-size: 0.75rem;
        font-weight: 300;
        text-transform: capitalize;

        text-align: center;
        color: $bleachr-white;
        background-color: #00897b; // default

        padding: 6px 12px;
        border-radius: 12px;
        min-width: auto;
        margin-right: 6px;
    }
}

@media only screen and (max-width: 768px) {
    .pill {
        padding: 4px 2px;
    }
}
