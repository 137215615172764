.tennis-one-nav-container {
    width: 225px;
    padding-right: calc(0.5rem + 2px);
    border-right: 1px solid #afafaf;
    min-height: calc(100vh - 7rem);

    .module-container {
        cursor: pointer;
    }
}

.tennis-one-module-container {
    flex: 1;
    margin-top: 2.5rem;
    padding-left: 0.5rem;

    .account-section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 0.5rem 0.5rem;
        margin: 0.5rem 0rem 0rem;
        background: $bleachr-white;
        min-height: 36px;
        position: sticky;
        top: 4rem;
        z-index: 2;

        .flex-1 {
            flex: 1;
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            font-weight: 500;

            .title-text,
            .indicator-container {
                padding: 0rem 1rem 0rem 0rem;
            }
        }

        button {
            &:first-child {
                margin-right: 1rem;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
